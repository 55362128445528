import Link from './Link';
import React from 'react';
import Moped from '../models/Moped';
import Img from './Img';

const MopedCard: React.FC<{ moped: Moped }> = ({ moped }) => (
    <Link className="moped-link" to={`/mopeds/${moped.id}`}>
        <div tabIndex={0} className="moped-card">
            {moped.image && (
                <div className="moped-image">
                    <Img
                        key={moped.image}
                        alt={moped.displayName}
                        src={moped.previewImage || moped.image} />
                </div>
            )}
            {moped.brand && <h4 className="brand">{moped.brand}</h4>}
            <h2 className="name">{moped.displayName}</h2>
            {moped.cc && <h3 className="cc">{moped.cc}CC</h3>}
        </div>
    </Link>
)

export default MopedCard;