import firebase from 'firebase';
import Moped, { MopedColor } from "../models/Moped";

export default function mopedFromJson(id: string, data: firebase.firestore.DocumentData): Moped {
    if (data && data.displayName && data.archived !== true) {

        let colors: MopedColor[] = [];

        const colorsData = data.colors;
        if (colorsData && typeof colorsData === 'object') {
            colorsData.forEach(color => {
                if (color.color || color.image) {
                    colors.push({
                        color: color.color,
                        image: color.image
                    });
                }
            });
        }

        return {
            id: id,
            displayName: data.displayName,
            image: data.image,
            previewImage: data.previewImage,
            brand: data.brand,
            cc: data.cc,
            description: data.description,
            specs: data.specs,
            colors
        }
    }

    return null;
}