import firebase from './firebase';
import Moped from '../models/Moped';
import mopedFromJson from './mopedFromJson';

export default function listenToMoped(id: string, onMoped: (moped: Moped) => void): void {
    firebase.firestore().collection('mopeds').doc(id).onSnapshot((snapshot) => {
        const moped = mopedFromJson(id, snapshot.data());
        if (moped)
            onMoped(moped);
    });
}