import { AnimatePresence, AnimateSharedLayout, motion } from 'framer-motion';
import React, { Component } from 'react';
import Specs from '../models/Specs';
import * as Icons from 'react-feather';

import '../scss/moped-specs.scss';

export enum SpecType {
    SectionTitle,
    Spec
}

export interface MopedSpecsListItem {
    type: SpecType,
    key: string,
    value?: string
}

export interface MopedSpecsListProps {
    list: MopedSpecsListItem[],
    max?: number
}

export const MopedSpecsList: React.FC<MopedSpecsListProps> = ({ list, max }) => {

    let index = -1;

    return (
        <span>
            {
                list.slice(0, max).map(item => {

                    index += 1;

                    if (item.type === SpecType.Spec) {
                        return (
                            <div key={item.key} className="moped-spec">
                                <div className="key">{item.key}</div>
                                <div className="value">{item.value}</div>
                            </div>
                        );
                    } else if (item.type === SpecType.SectionTitle) {
                        return (
                            <div key={item.key}>
                                {
                                    index > 0 && <br className="spacing small" />
                                }
                                <div className="spec-section-title">{item.key}</div>
                            </div>
                        );
                    }
                })
            }
        </span>
    );
}

export interface MopedSpecsProps {
    specs: Specs
}

export default class MopedSpecs extends Component<MopedSpecsProps> {

    state = {
        expanded: false
    }

    setExpanded(expanded: boolean) {
        this.setState({ expanded });
    }

    render() {
        const { specs } = this.props;
        const { expanded } = this.state;

        const keys = Object.keys(specs);
        let list: MopedSpecsListItem[] = [];

        for (let key of keys) {
            const value = specs[key];

            if (typeof value === 'string') {
                list.push({
                    type: SpecType.Spec,
                    key: key,
                    value: value
                });
            } else {
                const sectionKeys = Object.keys(value);
                list.push({
                    type: SpecType.SectionTitle,
                    key: key
                });

                for (key of sectionKeys) {
                    list.push({
                        type: SpecType.Spec,
                        key: key,
                        value: value[key]
                    });
                }
            }
        }

        const layoutId = 'specs';

        const transition = {
            delay: 0,
            duration: 0.25,
            ease: [0.5, 0.5, 0, 1],
        };

        return (
            <div className="moped-specs-wrapper">
                <AnimateSharedLayout type='crossfade'>
                    <motion.div transition={transition} layoutId={layoutId} className="moped-specs">
                        <MopedSpecsList list={list} max={5} />
                        {
                            list.length > 5 ?
                                <>
                                    <div className="moped-spec" style={{
                                        padding: '0 20px'
                                    }}>
                                        <div className="key">
                                            ...
                                        </div>
                                    </div>
                                    <div className="overflow" onClick={() => {
                                        this.setExpanded(true);
                                    }} tabIndex={0}>
                                        <span className="more">
                                            More
                                        </span>
                                    </div>
                                </> : <br className="spacing small" />
                        }
                    </motion.div>

                    <div onClick={() => { this.setExpanded(false) }} className={`specs-modal-overlay${expanded ? ' visible' : ''}`} />

                    <AnimatePresence>
                        {expanded && (
                            <motion.div transition={transition} layoutId={layoutId} className="moped-specs modal">
                                <div className="specs-list-header">
                                    <h1>Specifications</h1>
                                    <div className="spacer" />
                                    <button aria-label="close" role='button' className="close-button" tabIndex={0} onClick={() => {
                                        this.setExpanded(false);
                                    }}>
                                        <i className="icon">
                                            <Icons.X />
                                        </i>
                                    </button>
                                </div>
                                <MopedSpecsList list={list} />
                            </motion.div>
                        )}
                    </AnimatePresence>
                </AnimateSharedLayout>
            </div>
        )
    }
}