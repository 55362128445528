import clsx from 'clsx';
import React, { useState } from 'react';
import '../scss/image.scss';

export interface ImgProps {
    alt?: string,
    src?: string,
    className?: string,
    id?: string
}

const Img: React.FC<ImgProps> = ({ id, className, alt, src }) => {
    let [loaded, setLoaded] = useState(false);

    if (typeof Image !== 'undefined') {
        let image = new Image();
        image.onload = () => { setLoaded(true) }
        image.src = src;
    }

    return (
        <img className={clsx('lr-image', loaded ? 'loaded' : '', className)} id={id} alt={alt} src={src} />
    );
}

export default Img;