import React, { Component } from 'react';
import Button from '../components/Button';
import MopedSpecs from '../components/MopedSpecs';
import MopedCard from '../components/MopedCard';
import listenToMoped from '../firebase/listenToMoped';
import getMopeds from '../firebase/getMopeds';
import Moped, { MopedColor } from '../models/Moped';
import Link from '../components/Link';
import * as Icons from 'react-feather';
import Img from '../components/Img';

import '../scss/moped-page.scss';
import SEO from '../components/seo';
import { mopedsPath } from '../gatsby/routes';

export interface MopedTemplateProps {
    pageContext: {
        mopedId: string,
        moped: Moped
    }
}

const MopedTemplate: React.FC<MopedTemplateProps> = ({ pageContext }) => {
    return <_MopedTemplate pageContext={pageContext} key={pageContext.mopedId || ''} />
}

export default MopedTemplate;

class _MopedTemplate extends Component<MopedTemplateProps> {

    state: {
        moped: Moped,
        scrolledSidebar: boolean,
        otherMopeds: Moped[],
        currentColor: string,
        switchedColor: boolean,
        changing: boolean
    } = {
            moped: null,
            scrolledSidebar: false,
            otherMopeds: [],
            currentColor: null,
            switchedColor: false,
            changing: false
        }

    componentDidMount() {
        console.log('mopedId: ' + this.props.pageContext);
        this.setMoped();
        this.setOtherMopeds();
        this.init();
    }

    componentDidUpdate() {
        this.init();
        this.checkSidebar();
    }

    componentWillUnmount() {
        this.clearScrollTop();
    }

    init() {
        this.setScrollTop();
    }

    async setMoped() {
        const { mopedId } = this.props.pageContext;

        if (!mopedId) return;

        try {
            listenToMoped(mopedId, moped => this.setState({
                moped
            }));
        } catch (_) {
            console.log('...');
        }
    }

    async setOtherMopeds() {
        const { mopedId } = this.props.pageContext;

        if (!mopedId) return;

        try {
            const otherMopeds = await getMopeds({
                exclude: [mopedId],
                max: 3
            });

            this.setState({
                otherMopeds
            });
        } catch (_) {
            this.setState({
                otherMopeds: []
            });
        }
    }

    clearScrollTop() {
        window.onscroll = null;
    }

    setScrollTop() {
        this.checkSidebar();

        window.onresize = () => {
            this.checkSidebar();
        }

        window.onscroll = () => {
            this.checkSidebar();
        }
    }

    checkSidebar() {
        let scrolled = Math.max(document.body.scrollTop, document.documentElement.scrollTop, window.pageYOffset);
        let scrollDifference = document.getElementById('info-section')?.scrollHeight + 85 - scrolled;
        let viewportHeight = window.innerHeight;
        let viewportWidth = window.innerWidth;
        let scrollRemaining = scrollDifference - viewportHeight;
        let scrolledSidebar = scrollRemaining <= 0;

        if (scrolledSidebar != this.state.scrolledSidebar && viewportWidth > 720) {
            this.setState({
                scrolledSidebar
            });
        }
    }

    async setColor(color: string) {
        if (this.state.currentColor !== color && !this.state.changing) {
            this.setState({ currentColor: color, switchedColor: true, changing: true });
            await new Promise(res => {
                setInterval(res, 360);
            });
            this.setState({ changing: false });
        }
    }

    render() {

        const { moped: stateMoped, scrolledSidebar, otherMopeds } = this.state;

        let moped = stateMoped;

        if (!this.state.currentColor && moped?.colors && moped.colors.length > 0) {
            this.state.currentColor = moped.colors[0].color;
        }

        if (!moped) moped = this.props.pageContext.moped;
        if (!moped) return <div style={{ height: '100vh' }} />

        let color: MopedColor;
        const colors = moped?.colors?.length > 0 ? moped?.colors : null;

        const { currentColor, switchedColor } = this.state;

        colors?.forEach(mopedColor => {
            if (currentColor === mopedColor.color)
                color = mopedColor;
        });

        return (
            <div className="moped-template" key={moped.id}>
                <SEO title={`${moped.brand ? `${moped.brand} ` : ''}${moped.displayName}`} description={moped.description} />
                <div className={`moped-page${scrolledSidebar ? ' scrolled' : ''}`}>
                    <div className="image-section-push"></div>
                    <div className="image-section">
                        {
                            colors && colors.length ? (

                                colors.map(color => {
                                    if (color && color.image)
                                        return (
                                            <div key={color.color} className={`moped-image${currentColor === color.color ? ` current${switchedColor ? ' switched' : ''}` : ''}`} >
                                                <Img src={color.image} alt={moped.displayName} />
                                            </div>
                                        );
                                })

                            ) : moped.image && (
                                <div key="color::default" className="moped-image current" >
                                    <Img alt={moped.displayName} src={moped.image} />
                                </div>
                            )
                        }
                    </div>
                    <div className="info-section" id="info-section">
                        <br className="spacing small desktop-only" />
                        <div className="header">
                            {moped.brand && <h3 className="brand">{moped.brand}</h3>}
                            <h1 className="name">{moped.displayName}</h1>
                            {moped.cc && <h2 className="cc">{moped.cc}CC</h2>}
                        </div>
                        {
                            moped.colors && color && (
                                <div>
                                    <br className="spacing" />
                                    <div className="colors">
                                        {
                                            moped.colors.map(color => {
                                                const current = color.color === currentColor;

                                                return (
                                                    <div onClick={() => {
                                                        this.setColor(color.color);
                                                    }} className={`color${current ? ' current' : ''}`} style={{
                                                        backgroundColor: color.color || 'black'
                                                    }} key={color.color}></div>
                                                );
                                            })
                                        }
                                    </div>
                                    <br className="spacing desktop-only" />
                                    <br className="spacing small mobile-only" />
                                </div>
                            )
                        }
                        {/* <br className="spacing" />
                        <Button big block>
                            Get Pricing
                        </Button>
                        <br className="spacing small" />
                        <Button big block outline>
                            Add To Favorites
                        </Button> */}
                        {
                            moped.description &&
                            <div>
                                <div className="description">
                                    {/* <br className="spacing" /> */}
                                    <p>
                                        {moped.description}
                                    </p>
                                </div>
                            </div>
                        }
                        {
                            !moped.description && <br className="spacing small" />
                        }
                        {
                            moped.specs &&
                            <div>
                                <br className="spacing" />
                                <MopedSpecs specs={moped.specs} />
                                <br className="spacing" />
                                <br className="spacing small" />
                            </div>
                        }
                    </div>
                </div>
                {
                    otherMopeds && otherMopeds.length > 2 && (
                        <div className="other-mopeds">
                            <div className="header">
                                <h1>You might also like</h1>
                                <div className="see-more">
                                    <Link to={mopedsPath}>See More <span className="icon"><Icons.ArrowRight size={20} /></span></Link>
                                </div>
                            </div>
                            <div className="grid">
                                {
                                    otherMopeds.slice(0, 3).map(moped => {
                                        return <MopedCard key={moped.id} moped={moped} />
                                    })
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        )
    }
}