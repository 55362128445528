import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { Link as GatsbyLink } from 'gatsby';

export interface LinkProps {
    to?: string,
    activeClassName?: string,
    className?: string,
    id?: string,
    transition?: boolean
}

const Link: React.FC<LinkProps> = ({ to, activeClassName, children, className, id, transition }) => {
    return (
        // <AniLink
        //     className={className}
        //     id={id}
        //     fade
        //     duration={0.0001}
        //     to={to}
        //     activeClassName={activeClassName}>
        //     {children}
        // </AniLink>
        <GatsbyLink
            className={className}
            id={id}
            to={to}
            activeClassName={activeClassName}>
            {children}
        </GatsbyLink>
    )
}

export default Link;