import firebase from '../firebase/firebase';
import Moped from '../models/Moped';
import mopedFromJson from './mopedFromJson';

export interface GetMopedsParameters {
    /** The maximum amount of mopeds */
    max?: number,
    /** Moped Ids to exclude */
    exclude?: string[]
}

export default async function getMopeds(params?: GetMopedsParameters): Promise<Moped[]> {

    let max = 10000, exclude = [''];

    if (params) {
        max = params.max || max;
        exclude = params.exclude || [''];
    }

    const docs = (await firebase.firestore().collection('mopeds').get()).docs;

    let mopeds: Moped[] = [];

    for (let doc of docs) {
        const data = doc.data();

        if (data.archived !== true && data.displayName && (exclude ? !exclude.includes(doc.id) : true)) {
            mopeds.push(mopedFromJson(doc.id, data));
        }
    }

    return mopeds.slice(0, max ? max + 1 : null);
}